import React from 'react';
import './index.less';

/**
 * pageType pre 上一页  next 下一页
 * imgUrl 图片路径
 * onChange 调用方法
 */

const IconFont = Loader.loadBaseComponent('IconFont');
const ImageView = Loader.loadBusinessComponent('ImageView');

class PageDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.changeCurrentImg);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.changeCurrentImg);
  }

	changeCurrentImg = (e) => {
    const { id, pageType, classNameNode = '', onChange } = this.props;
		try {
      const SELECT = window.getSelection().focusNode.className === classNameNode;
			if (SELECT) {
				return;
			}
		} catch (e) {}
		if (pageType === 'pre' && e.keyCode === 37) {
      onChange && onChange(id, pageType);
    }
    if (pageType === 'next' && e.keyCode === 39) {
      onChange && onChange(id, pageType);
		}
	};
  render() {
    const {
      onChange,
      imgUrl,
      id,
      className = '',
      pageType = 'next'
    } = this.props;
    return (
      <React.Fragment>
        <div
          className={`page-details ${className}`}
          onClick={() => onChange(id, pageType)}>
          <div className="page-image">
            <ImageView src={imgUrl} />
          </div>
          {pageType === 'next' ? (
            <p className="pre">
              下一个
              <IconFont type={'icon-S_Arrow_BigRight'} theme="outlined" />
            </p>
          ) : (
            <p className="pre">
              <IconFont type={'icon-S_Arrow_BigLeft'} theme="outlined" />
              上一个
            </p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PageDetail;
